const kr = {
  marketplaceDependent: {
    form: {
      targeting_link:
        "/academy/blog/targeting/hedefleme-nasil-yapilir-hedefleme-turleri-nelerdir",
      bid_link:
        "/academy/blog/management/teklif-nasil-belirlenir-teklif-turleri-nelerdir",
      frequency_link:
        "/academy/blog/measuring/gonderim-turu-nedir-zamanlanmis-rapor-nasil-olusturulur",
      report_type_link: "/academy/blog/measuring/ozel-rapor-nasil-olusturulur",
      product_link:
        "/academy/blog/optimization/urun-secimi-yaparken-nelere-dikkat-edilmeli",
    },
    campaign_form_targeting_text: "캠페인의 타겟팅 전략을 설정할 수 있습니다.",
    campaign_form_budget_text:
      "캠페인 예산 유형을 선택하고 예산을 설정하세요. 지출 금액이 예산에 도달하면 캠페인이 자동으로 중지됩니다.",
    campaign_form_creative_text:
      "캠페인에 사용할 크리에이티브를 업로드할 수 있습니다.",
    invoice_modal_help_text:
      "결제 방법 선택 버튼을 클릭하여 계속하면 징수될 금액이 환불되지 않음을 확인한 것으로 간주됩니다.",
    invoice_general_error_modal_title: "잔액 불러오기 실패!",
    invoice_general_error_modal_text: "오류가 발생했습니다.",
    invoice_unknown_error_modal_text: "예기치 않은 오류가 발생했습니다.",
    invoice_product_id_error_modal_text: "제품 ID 필드는 필수입니다.",
    invoice_signature_error_modal_text: "서명 확인 실패.",
    invoice_transaction_error_modal_text: "이미 사용된 거래 ID입니다.",
    invoice_store_error_modal_text:
      "해당 스토어는 지정된 회사에 속하지 않습니다.",
    invoice_balance_error_modal_text: "스토어 잔액이 부족합니다.",
    invoice_error_modal_title: "잔액 불러오기 실패!",
    invoice_error_modal_text: "오류가 발생했습니다.",
  },
  marketplaceDependentTable: {
    CPC: "CPC",
    CPM: "CPM",
    RoAS: "ROAS",
  },
  marketplaceDependentSettings: {
    api_link: "https://docs.gowit.com/reference/getting-started-1",
    auto_impression_link: "https://docs.gowit.com/reference/sendevent",
    auction_link:
      "https://docs.gowit.com/docs/what-type-of-auctions-are-offered",
    product_link: "https://docs.gowit.com/docs/filters#what-is-a-product-rate",
    stock_link: "https://docs.gowit.com/docs/filters#what-is-a-product-stock",
    targeting_link:
      "https://docs.gowit.com/docs/filters#what-is-a-demographic-targeting-feature",
    bidding_link: "https://docs.gowit.com/docs/bidding-types",
  },
  sider: {
    user: {
      campaign: "캠페인",
      report: "리포트",
      campaigns: "캠페인",
      reports: "리포트",
      invoices: "송장",
      academy: "아카데미",
    },
    admin: {
      campaign: "캠페인",
      advertisers: "광고주",
      agencies: "에이전시",
      invoices: "송장",
      finance: "재무",
      transactions: "거래",
      coupons: "쿠폰",
      access: "접근",
      users: "사용자",
      invited: "초대됨",
      synchronization: "동기화 기록",
      activity: "활동 기록",
      report: "리포트",
      settings: "설정",
      general: "일반 정보",
      integration: "통합 정보",
      advertiser: "광고주 설정",
      auction: "경매 설정",
      single: "캠페인 설정",
      campaigns: "캠페인",
      reports: "리포트",
      admin_report: "리포트",
      ad: "광고",
      placement: "광고 배치",
      creatives: "크리에이티브",
      review: "광고 검토",
      restrictions: "제한 설정",
    },
  },
  components: {
    adminBar: {
      rn: "현재 보고 있는 화면은",
      visible: "광고주 화면입니다.",
      back: "관리자 보기로 돌아가기",
    },
    advertiserSelect: {
      placeholder: "광고주 선택",
    },
    articles: {
      date: "캠페인은 언제 시작하고 언제 종료되나요?",
      budget: "예산이란 무엇인가요? 예산을 선택할 때 고려할 사항은 무엇인가요?",
      bid: "입찰이란 무엇이며 입찰 유형은 무엇인가요?",
      targeting: "타겟 유형 및 올바른 잠재고객 타겟팅 방법?",
      frequency: "리포트에서 전달 유형이란 무엇인가요?",
      type: "커스텀 리포트는 어떻게 생성하나요?",
      product: "제품을 선택할 때 고려할 사항은 무엇인가요?",
      store: "스토어를 선택할 때 고려할 사항은 무엇인가요?",
    },
    breadcrumb: {
      finance: "거래",
      settings: "일반 정보",
      access: "사용자",
    },
    bulkUpdate: {
      list: "목록 선택됨",
      archive: "아카이브 선택됨",
      pause: "일시 중지 항목 선택됨",
      activate: "활성 항목 선택됨",
      selected: "{{value}}개 단위 선택됨",
      bid: "선택한 입찰 편집",
      bidLabel: "입찰",
      bidHelp: "선택한 행의 입찰 정보가 업데이트됩니다.",
      row: "{{value}} 행",
      count: "선택됨:",
      updateAllBids: "입찰 편집",
      updateAllBidsText: "표에 표시된 모든 행의 입찰을 편집할 수 있습니다.",
      updatingAllBids: "모든 행의 입찰을 편집하고 있습니다.",
      updateAllBidsHelp: "표에 표시된 모든 행의 입찰이 업데이트됩니다.",
    },
    download: {
      campaigns: "캠페인",
      advertisers: "광고주",
      agencies: "에이전시",
      transactions: "거래",
      coupons: "쿠폰",
      users: "사용자",
      sync_logs: "동기화 로그",
      reports: "리포트",
      query: "성과 리포트",
      PRODUCTS: "제품",
      KEYWORDS: "키워드",
      NEGATIVE_KEYWORDS: "제외 키워드",
      AGE_RANGES: "연령",
      CITIES: "도시",
      PLATFORMS: "플랫폼",
      GENDERS: "성별",
      PLACEMENTS: "광고 배치",
      CUSTOMERS: "고객",
      CATEGORIES: "카테고리",
      NEGATIVE_CATEGORIES: "제외 카테고리",
      CREATIVES: "크리에이티브",
    },
    info: {
      firstTipCreate:
        "추천 설정을 선택하면 성공적인 캠페인을 쉽게 만들 수 있습니다. 추천 설정은 광고 성과를 최적화해줍니다.",
      secondTipCreate:
        "캠페인 성과를 높이려면 충분한 재고와 높은 점수를 가진 제품 및 Buybox 우승 제품을 선택할 수 있습니다.",
      thirdTipCreate:
        "광고 예산을 늘리면 제품이 고객에게 더 눈에 띄어 클릭 수와 판매량을 증가시킬 수 있습니다.",
      forthTipCreate:
        "캠페인에서 높은 입찰가를 설정하면 제품이 더 좋은 위치에 표시될 수 있습니다.",
    },
    invite: {
      selected: "{{value}}개 단위 선택됨",
      invite: "초대 항목 선택됨",
      reinvite: "재초대 항목 선택됨",
    },
    modal: {
      campaign: {
        create: "새 캠페인 생성",
        success: "캠페인이 성공적으로 생성되었습니다",
        successText:
          '"확인" 버튼을 클릭하여 캠페인 세부 페이지로 계속 진행할 수 있습니다.',
      },
      campaignDetails: {
        products: "제품 선택",
        keywords: "키워드 추가",
        update: "캠페인 업데이트",
        negative_keywords: "제외 키워드 추가",
        products_btn: "제품 추가",
        keywords_btn: "키워드 추가",
        negative_keywords_btn: "제외 키워드 추가",
        update_btn: "업데이트",
        create_agency: "새 에이전시 추가",
        update_agency: "에이전시 업데이트",
        create_agency_btn: "추가",
        update_agency_btn: "업데이트",
        create_user: "새 사용자 추가",
        create_user_marketplace: "관리자 사용자 추가",
        create_user_marketplace_btn: "추가",
        create_user_advertiser: "광고주 사용자 추가",
        create_user_advertiser_btn: "추가",
        select_advertiser: "광고주 선택",
        select_advertiser_btn: "계속",
        update_advertiser: "광고주 업데이트",
        update_advertiser_btn: "업데이트",
        invoice: "잔액 추가",
        invoice_btn: "결제 방법 선택",
        coupons: "쿠폰 정의",
        coupons_btn: "쿠폰 정의",
        transactions: "잔액 추가",
        transactions_btn: "잔액 추가",
        categories: "새 카테고리 추가",
        categories_btn: "카테고리 추가",
        negative_categories: "제외 카테고리 추가",
        negative_categories_btn: "카테고리 추가",
        transaction_details: "거래 세부 정보",
        settings: "변경 사항 저장",
        settings_btn: "저장",
        creatives: "크리에이티브 추가",
        creatives_btn: "크리에이티브 추가",
        approve_balance: "잔액 추가",
        approve_balance_btn: "거래 확인",
        approve_coupon: "쿠폰 추가",
        approve_coupon_btn: "쿠폰 확인",
        payment_options: "잔액 추가",
        payment_options_btn: "불러오기",
        add_user: "새 사용자 초대",
        add_user_btn: "초대 전송",
        targeting_settings: "광고주 선택",
        targeting_settings_btn: "선택",
        update_transaction: "송장 번호 편집",
        update_transaction_btn: "저장",
        select_user: "사용자 유형 선택",
        select_user_btn: "계속",
        select_language: "언어 변경",
        select_language_btn: "변경",
        campaign_form: "캠페인 요약",
        campaign_form_btn: "캠페인 생성",
        update_bid: "입찰 편집",
        update_bid_btn: "편집",
        reject_creative: "창의 거부",
        reject_creative_btn: "확인",
      },
      selectCampaign: {
        title: "캠페인 유형 선택",
        text: "캠페인 유형을 선택하세요.",
        soon: "곧",
        sponsored_product_title: "스폰서 제품",
        sponsored_product_text:
          "관련 키워드를 검색하거나 유사한 제품을 보는 쇼핑객에게 홍보하세요.",
        sponsored_brand_title: "스폰서 브랜드",
        sponsored_brand_text:
          "풍부하고 매력적인 크리에이티브로 쇼핑객이 당신의 브랜드와 제품을 발견하도록 도우세요.",
        sponsored_display_title: "스폰서 디스플레이",
        sponsored_display_text:
          "관련 있는 잠재고객에게 온사이트 및 오프사이트에서 도달하여 비즈니스를 성장시키세요.",
        sponsored_store_title: "스폰서 스토어",
        sponsored_store_text:
          "스토어의 도달 범위를 넓히고 쇼핑객이 당신의 스토어를 발견하도록 도와주세요. 목표한 쇼핑 경험을 제공하세요.",
      },
      success: {
        advertisers_success_title: "초대가 전송되었습니다",
        advertisers_success_text:
          "입력한 이메일 주소로 초대가 전송되었습니다. 사용자는 초대 링크를 클릭하여 계정을 생성해야 계정에 액세스할 수 있습니다.",
        reports_success_title: "리포트가 성공적으로 생성되었습니다",
        reports_success_text:
          '"확인" 버튼을 클릭하여 리포트 세부 페이지로 계속 진행할 수 있습니다.',
        invited_success_title: "사용자가 초대되었습니다",
        invited_success_text:
          "사용자가 목록에 표시되려면 초대 이메일을 클릭하여 비밀번호를 생성해야 합니다.",
        agencies_success_title: "에이전시가 성공적으로 생성되었습니다",
        agencies_success_text: "nan",
        multiple_advertisers_success_title: "초대가 전송되었습니다",
        multiple_advertisers_success_text: "선택한 광고주가 초대되었습니다.",
        invoice_success_title: "잔액 로드에 성공했습니다!",
        invoice_success_text:
          "잔액이 로드되었습니다. 새 캠페인을 만들 수 있습니다.",
        create_advertiser_success_title: "광고주 추가에 성공했습니다!",
        create_advertiser_success_text:
          "활성화하려면 광고주를 초대해야 합니다.",
        payment_success_title: "잔액 로드 요청이 접수되었습니다!",
        payment_success_text:
          "자금 이체 또는 EFT 결제가 승인되면 계정으로 입금됩니다. 승인 과정은 영업일 기준 2-3일이 소요될 수 있습니다.",
        craftgate_success_title: "잔액 로드 요청이 접수되었습니다!",
        craftgate_success_text: "잔액이 곧 계정으로 이체됩니다.",
      },
      error: {
        craftgate_title: "잔액을 로드할 수 없습니다!",
        craftgate_text: "신용 카드 로드에 실패했습니다.",
      },
      warning: {
        campaign_warning_title: "이 페이지에서 나가시겠습니까?",
        campaign_warning_text: "변경 사항이 저장되지 않습니다!",
      },
    },
    navbar: {
      search: "검색하려면 입력하세요...",
      help: "도움말 및 지원",
      site: "판매자 패널로 돌아가기",
    },
    navbarPopover: {
      account: "계정 변경",
      language: "언어 변경",
      logout: "로그아웃",
    },
    notification: {
      formTitle: "양식이 완전히 작성되지 않았습니다!",
      formText: "빈칸과 필수 필드를 채워주세요!",
      paused: "{{value}} 중지 성공.",
      active: "{{value}} 활성화 성공.",
      campaigns: "캠페인",
      reports: "리포트",
      products: "제품",
      keywords: "키워드",
      negative_keywords: "제외 키워드",
      categories: "카테고리",
      negative_categories: "제외 카테고리",
      placements: "광고 배치",
      users: "사용자",
      advertisers: "광고주",
      agencies: "에이전시",
      ad_placements: "광고 배치",
      creatives: "크리에이티브",
      account_users: "사용자",
      statusError: "오류가 발생했습니다.",
      activeReport: "리포트 아카이브가 완료되었습니다.",
      archivedReport: "리포트 아카이브 해제가 완료되었습니다.",
      activeCampaign: "캠페인 아카이브가 완료되었습니다.",
      pausedCampaign: "캠페인 아카이브가 완료되었습니다.",
      archivedCampaign: "캠페인 아카이브 해제가 완료되었습니다.",
      activeAgency: "에이전시 아카이브가 완료되었습니다.",
      archivedAgency: "에이전시 아카이브 해제가 완료되었습니다.",
      invitedAdvertiser: "광고주 아카이브가 완료되었습니다.",
      activeAdvertiser: "광고주 아카이브 해제가 완료되었습니다.",
      archivedAdvertiser: "광고주 아카이브가 완료되었습니다.",
      archived_reports_bulk: "리포트 아카이브가 완료되었습니다.",
      archived_campaigns_bulk: "캠페인 아카이브가 완료되었습니다.",
      archived_users_bulk: "사용자 아카이브가 완료되었습니다.",
      archived_advertisers_bulk: "광고주 아카이브가 완료되었습니다.",
      archived_agencies_bulk: "에이전시 아카이브가 완료되었습니다.",
      archived_account_users_bulk: "사용자 아카이브가 완료되었습니다.",
      keyword: "최소한 1개의 키워드를 추가하세요.",
      category: "최소한 1개의 카테고리를 추가하세요.",
      invite: "{{value}}개의 초대가 성공적으로 발송되었습니다.",
      full_invite: "모든 초대가 성공적으로 발송되었습니다.",
      rejected: "크리에이티브 거부가 성공적으로 완료되었습니다.",
      accepted: "크리에이티브 검증이 성공적으로 완료되었습니다.",
      uninvitedAdvertiser: "광고주 활성화가 성공적으로 완료되었습니다.",
      settings_success: "설정이 성공적으로 저장되었습니다.",
      previous_invite_sent_too_recently:
        "{{value}}개의 초대가 너무 최근에 발송되었습니다. 다시 시도하려면 기다려 주세요.",
      user_email_is_already_registered:
        "{{value}}개의 이메일 주소가 이미 등록되어 있습니다.",
      copiedCampaign: "캠페인 복사가 성공적으로 완료되었습니다.",
      copyError: "캠페인 복사 중 오류가 발생했습니다.",
      editableFieldError:
        "최소값보다 큰 값을 입력하세요. 그렇지 않으면 자동으로 조정됩니다.",
      active_account_user: "사용자의 아카이브가 성공했습니다.",
      archived_account_user: "사용자 아카이브가 성공적으로 완료되었습니다.",
      bid: "사용자 아카이브 해제가 성공적으로 완료되었습니다.",
      newBid: "{{value}}개의 행의 입찰이 업데이트되었습니다.",
    },
    suggestedTag: {
      text: "추천",
    },
    campaignCard: {
      graph: "그래프로 표시",
      hide: "숨기기",
      message: "지난 30일",
    },
    campaignForm: {
      firstStep: {
        keywordStepTitle: "키워드 정보",
        categoryStepTitle: "카테고리 정보",
        targetingStepTitle: "잠재고객 정보",
        campaignInfoTitle: "캠페인 정보",
        campaignInfoText:
          "캠페인 테이블에 표시할 이름을 입력해주세요. 이 이름은 고객에게는 보이지 않습니다. 광고하려는 제품과 관련된 이름을 선택하는 것이 좋습니다.",
        campaignLabel: "캠페인 이름",
        campaignSub: "캠페인 이름을 입력하세요.",
        dateInfoTitle: "캠페인 날짜",
        dateInfoText: "캠페인의 기간을 설정하세요.",
        startDateLabel: "시작 날짜",
        startDateSub: "캠페인 시작 날짜를 선택하세요.",
        stopped: "지속",
        stoppedText: "캠페인이 오늘 시작되어 중지할 때까지 계속됩니다.",
        stoppedSub: '시작 "날짜": {{value}}',
        defined: "시작 - 종료 날짜",
        definedText: "캠페인이 실행될 날짜 범위를 선택할 수 있습니다.",
        budget: "예산",
        totalBudget: "총 예산 한도",
        totalBudgetLabel: "총 예산",
        dailyBudgetLabel: "일일 예산",
        dailyBudgetExtra: "캠페인의 일일 지출 한도는 설정한 최대 금액입니다.",
        dailyBudgetFilter: "일일 예산",
        dailyBudgetText: "일일 예산을 설정할 수 있습니다.",
        totalBudgetExtra: "캠페인은 매일 설정한 최대 금액까지만 지출됩니다.",
        flexibleBudgetLabel: "일일 예산",
        totalBudgetFilter: "총 예산",
        totalBudgetText: "총 예산을 설정할 수 있습니다.",
        bid: "입찰",
        bidText:
          "캠페인의 입찰 유형을 설정하세요. 광고 비용은 선택한 입찰 유형에 따라 클릭당 발생합니다.",
        dynamicBid: "자동 입찰",
        dynamicBidText: "클릭당 비용이 자동으로 결정됩니다.",
        targetAcosTitle: "타겟 ACoS",
        targetAcosText: "광고비로 매출의 몇 퍼센트를 사용할지 선택하세요.",
        default: "기본",
        advanced: "고급",
        fixedBid: "고정 입찰",
        fixedBidText:
          "키워드 및 카테고리에 대해 고정된 클릭당 비용을 설정합니다. 캠페인은 타겟팅 선호도에 따라 고객에게 표시됩니다.",
        productTitle: "제품 선택",
        productText: "광고하려는 제품을 선택하세요.",
        acosTooltip:
          "ACoS는 판매당 광고비를 의미합니다. 즉, 판매 수익의 얼마를 광고비로 사용할지를 보여주는 비율입니다. 예를 들어, ACoS 값이 10%인 캠페인에서는 10TL 매출당 1TL을 광고에 사용합니다.",
        autoTargeting: "자동 타겟팅",
        autoTargetingText:
          "캠페인에 가장 적합한 키워드 또는 카테고리가 타겟팅됩니다.",
        manualTargeting: "수동 타겟팅",
        manualTargetingText:
          "캠페인에 표시하려는 키워드 또는 카테고리를 타겟팅할 수 있습니다.",
        suggestedBidText: '추천 "입찰":',
        acosError: "ACoS는 0.0001-100 사이여야 합니다.",
        creativeTitle: "크리에이티브 정보",
        videoText: "캠페인에 사용할 크리에이티브를 업로드할 수 있습니다.",
        urlTitle: "리디렉션 URL",
        urlText:
          "광고를 클릭한 사용자가 이동할 페이지입니다. 사용자를 마켓플레이스 내의 페이지로 리디렉션해야 합니다.",
        draggerTitle:
          ".PNG, .JPG 파일을 업로드하여 크리에이티브를 업로드하거나",
        draggerText: "이곳에 드래그 앤 드롭하세요",
        creativeLabel: "크리에이티브 이름",
        size: "크기:",
        listWarningSize: "크리에이티브의 최대 파일 크기는 5120 KB입니다.",
        listWarningType: ".jpg .png 허용됩니다.",
        listWarningRecommended:
          "광고 게재 위치에 대한 크리에이티브 크기와 허용되는 크리에이티브 크기를 확인할 수 있습니다.",
        modalTitle: "크리에이티브 크기",
        modalText:
          "나열된 모든 크기로 크리에이티브를 업로드할 수 있습니다. 권장된 크기로 크리에이티브를 업로드하면 광고 성과가 향상됩니다.",
        recommended: "추천 크기",
        accepted: "허용 크기",
        creativeError: "크리에이티브 이름을 입력하세요.",
        urlError: "URL을 입력하세요.",
        budgetError: "예산은 {{value}} 이상이어야 합니다.",
        budgetErrorMessage: "예산을 입력하세요.",
        invalid: "잘못된 크기",
        advancedOptions: "고급 옵션",
        placements: "광고 위치",
        placementsText: "광고를 표시할 영역을 선택하세요.",
        in_search: "검색 결과",
        in_search_text: "관련 검색 결과에 광고가 표시됩니다.",
        in_product_detail_page: "제품 상세 페이지",
        in_product_detail_page_text:
          "관련 제품 상세 페이지에 광고가 표시됩니다.",
        in_category: "카테고리",
        in_category_text: "관련 카테고리 페이지에 광고가 표시됩니다.",
        in_home_page: "홈페이지",
        in_home_page_text: "홈페이지에 광고가 표시됩니다.",
        in_collection: "컬렉션",
        in_collection_text: "관련 컬렉션 페이지에 광고가 표시됩니다.",
        storeTitle: "매장 선택",
        storeText: "광고할 매장을 선택하세요.",
        info: "일반 정보",
        advertiser: '광고주 "이름":',
        budgetType: '예산 "유형":',
        bidType: '입찰 "유형":',
        standart: "자동 입찰",
        fixed: "고정 입찰",
        max_acos: "타겟 ACoS",
        fixed_info: "입찰:",
        max_acos_info: "ACoS:",
        targeting: "타겟팅",
        custom: "커스텀",
        durationText:
          "캠페인이 매일 일일 예산 목표에 도달하면 <strong>{{value}}일 동안</strong> 활성 상태를 유지합니다.",
        languageTitle: "창의적인 언어 선택",
        languageSearch: "언어 검색...",
        autoTargetingProductWarning:
          "자동 타겟팅을 활성화하려면 <strong>연관된 제품</strong> 단계에서 최소 <strong>“1”</strong>개의 제품을 선택해야 합니다.",
        connectedProductsTitle: "연관된 제품",
        connectedProductsText:
          "위의 크리에이티브와 연관된, 당신을 위해 특별히 선별된 제품들입니다.",
        showProducts: "연관된 제품 보기",
        showProductsText:
          "이 옵션을 활성화하여 크리에이티브와 연관된 제품을 포함하세요. 관련 제품을 크리에이티브에 연결하면 광고의 관련성을 높이는 데 도움이 됩니다.",
        promoteProduct: "제품 홍보",
        promoteProductText:
          "이 기능은 캠페인에 추가된 제품이 눈에 띄는 위치에 표시되도록 보장합니다.",
        promoteProductWarning:
          "선택한 후에는 이 옵션을 변경할 수 없음을 유의하세요.",
        closeProducts: "연관된 제품 닫기",
        closeProductsText:
          "크리에이티브에 연관된 제품을 사용하지 않는 것은 권장되지 않습니다. 광고의 관련성이 떨어질 수 있습니다.",
        multiRegion: "지역 선택",
        multiRegionText:
          "캠페인을 여러 지역 또는 특정 지역에서 실행할 수 있습니다.",
        allRegions: "모든 지역",
        allRegionsText: "캠페인이 모든 지역에 게시됩니다.",
        selectedRegions: "선택된 지역",
        selectedRegionsText: "캠페인이 선택된 지역에서만 게시됩니다.",
        regionPlaceholder: "지역을 선택하세요",
        errorStates: {
          name: "캠페인 이름을 입력하세요.",
          keywordErr: "키워드는 고유해야 합니다.",
          advertiserErr: "광고주가 활성 상태가 아닙니다.",
          placementsError: "최소한 하나 이상의 광고 위치를 선택하세요.",
          productLimitErr: "최대 250개의 제품을 추가할 수 있습니다.",
          sameKeyword:
            "대상 키워드와 제외 키워드는 동일한 키워드를 포함할 수 없습니다.",
          duplicateKeyword: "키워드가 이미 캠페인에 추가되었습니다.",
          duplicateNegativeKeyword:
            "부정 키워드가 이미 캠페인에 추가되었습니다.",
          generalErr: "오류가 발생했습니다.",
          keywordLimitErr:
            "캠페인에는 최대 100개의 키워드를 추가할 수 있습니다.",
          categoryLimitErr:
            "캠페인에는 최대 100개의 카테고리를 추가할 수 있습니다.",
        },
      },
      secondStep: {
        wordTargeting: "키워드 타겟팅",
        wordTargetingText: "광고가 표시될 키워드를 결정하세요.",
        categorySub: "광고가 표시될 카테고리를 결정하세요.",
        autoTargeting: "자동 타겟팅",
        autoTargetingText:
          "가장 관련성 높은 키워드와 카테고리에 광고가 표시됩니다. 캠페인 세부 정보 페이지에서 키워드를 중지할 수 있습니다.",
        manualTargeting: "수동 타겟팅",
        manualTargetingText: "광고 키워드를 설정할 수 있습니다.",
        defaultBid: "기본 입찰",
        keywordTitle: "타겟 키워드",
        keywordText: "광고가 표시될 키워드를 추가하세요.",
        negativeTitle: "제외 키워드",
        negativeText: "광고가 표시되지 않기를 원하는 키워드를 추가하세요.",
        placementTitle: "타겟팅 유형",
        placementText:
          "캠페인이 표시될 특정 키워드 또는 카테고리를 타겟팅할 수 있습니다.",
        search: "키워드 타겟팅",
        searchText: "캠페인이 나타나기를 원하는 키워드를 타겟팅하세요.",
        categories: "카테고리 타겟팅",
        categoriesText: "캠페인을 표시할 카테고리를 타겟팅하세요.",
        categoryTargeting: "타겟팅",
        categoryTargetingTitle: "광고를 표시할 카테고리를 결정하세요.",
        categoryTargetingText: "광고를 표시할 카테고리를 추가하세요.",
        category: "타겟 카테고리",
        negativeCategoryTitle: "제외 카테고리",
        negativeCategoryText: "광고를 표시하지 않을 카테고리를 추가하세요.",
        autoCategoryText: "가장 적절한 카테고리에 광고가 표시됩니다.",
        manualCategoryText: "광고를 위한 타겟 카테고리를 설정할 수 있습니다.",
        placement: "타겟팅",
        targetingText: "캠페인의 타겟팅 전략을 설정할 수 있습니다.",
      },
      thirdStep: {
        targeting: "잠재고객 선택",
        targetingText: "광고를 표시할 잠재고객을 결정하세요.",
        autoTargeting: "자동 타겟팅",
        autoTargetingText:
          "가장 관련성 높은 키워드와 카테고리에 광고가 표시됩니다.",
        manualTargeting: "수동 타겟팅",
        manualTargetingText: "제품을 표시할 짐재고객을 결정할 수 있습니다.",
        platform: "플랫폼 타겟팅",
        platformText: "광고가 표시될 플랫폼 사용자를 결정하세요.",
        tablet: "태블릿",
        mobile: "모바일",
        app: "모바일 애플리케이션",
        desktop: "데스크톱",
        age: "연령대 타겟팅",
        ageText: "광고를 표시할 연령대를 결정하세요.",
        city: "도시 타겟팅",
        cityText: "광고를 표시할 도시를 결정하세요.",
        allCities: "모든 도시",
        selectedCities: "선택한 도시",
        cityPlaceholder: "도시 선택",
        customer: "고객 타겟팅",
        customerText: "쇼핑 기록을 기준으로 광고가 표시될 고객을 결정하세요.",
        presentCustomers: "기존 고객",
        presentCustomersText:
          "광고하는 제품을 이미 주문한 고객을 타겟팅하세요.",
        newCustomers: "신규 고객",
        newCustomersText:
          "광고하는 제품을 주문한 적이 없는 고객을 타겟팅하세요.",
        gender: "성별 타겟팅",
        genderText: "광고가 표시될 성별을 결정하세요.",
        female: "여성",
        male: "남성",
      },
    },
    columnPopover: {
      title: "열 편집",
    },
    empty: {
      product_title: "제품을 선택하세요.",
      store_title: "매장을 선택하세요.",
      dimensions_title: "측정기준을 아직 추가하지 않았습니다.",
      product_text:
        "왼쪽의 제품 목록에서 추가 버튼을 클릭하여 광고할 제품을 추가할 수 있습니다.",
      store_text: "광고할 매장을 추가할 수 있습니다.",
      dimensions_text:
        "측정기준을 추가하여 캠페인 결과의 세부 정보 수준을 커스텀할 수 있습니다.",
      null_title: "데이터 없음",
      null_text: "테이블에 데이터가 없습니다.",
      categories_title: "타겟팅할 카테고리를 추가하세요.",
      categories_text:
        "카테고리를 입력하거나 추천 카테고리 테이블에서 추가 버튼을 클릭하여 광고가 표시될 카테고리를 지정하세요.",
      keywords_title: "타겟팅할 키워드를 추가하세요.",
      keywords_text:
        "단어를 입력하거나 추천 키워드 테이블에서 추가 버튼을 클릭하여 광고가 표시될 키워드를 지정하세요.",
      negative_keywords_title: "아직 제외 키워드를 추가하지 않았습니다.",
      negative_keywords_text:
        "광고를 표시하지 않을 제외 키워드를 입력하여 지정할 수 있습니다.",
      negative_categories_title: "아직 제외 카테고리를 추가하지 않았습니다.",
      negative_categories_text:
        "광고를 표시하지 않을 제외 카테고리를 지정할 수 있습니다.",
      campaigns_table_title: "아직 캠페인을 생성하지 않았습니다.",
      campaigns_table_text:
        "새 캠페인 추가 버튼을 클릭하여 첫 캠페인을 생성할 수 있습니다.",
      reports_table_title: "아직 리포트를 생성하지 않았습니다.",
      reports_table_text:
        "새 리포트 추가 버튼을 클릭하여 첫 리포트를 생성할 수 있습니다.",
      invoices_table_title: "아직 잔액을 로드하지 않았습니다.",
      invoices_table_text:
        "계정 관리자에게 연락하여 광고 잔액을 추가할 수 있습니다.",
      advertisers_table_title: "계정에 연결된 광고주가 없습니다.",
      advertisers_table_text: "계정 관리자에게 연락하세요.",
      agencies_table_title: "아직 에이전시를 생성하지 않았습니다.",
      agencies_table_text:
        "새 에이전시 추가 버튼을 클릭하여 에이전시를 생성할 수 있습니다.",
      transactions_table_title: "아직 잔액을 로드하지 않았습니다.",
      transactions_table_text:
        "잔액 로드 버튼을 클릭하여 광고주 목록에 광고 잔액을 추가할 수 있습니다.",
      coupons_table_title: "아직 쿠폰을 생성하지 않았습니다.",
      coupons_table_text:
        "쿠폰 정의 버튼을 클릭하여 광고주에 대한 쿠폰을 정의할 수 있습니다.",
      account_users_table_title: "아직 사용자를 생성하지 않았습니다.",
      account_users_table_text:
        "새 사용자 추가 버튼을 클릭하여 사용자를 생성할 수 있습니다.",
      archived_table_reports_title: "아카이브된 리포트 없음",
      archived_table_campaigns_title: "아카이브된 캠페인 없음",
      archived_table_agencies_title: "아카이브된 에이전시 없음",
      archived_table_account_users_title: "아카이브된 사용자 없음",
      archived_table_advertisers_title: "아카이브된 광고주 없음",
      invited_table_users_title: "초대한 사용자 없음",
      searched_table_title: "검색 조건에 일치하는 기록이 없습니다.",
      searched_table_text: "nan",
      searched_title: "검색 조건에 일치하는 기록이 없습니다.",
      searched_text: "nan",
      products_table_title: "아직 제품을 추가하지 않았습니다.",
      products_table_text:
        "제품 추가 버튼을 클릭하여 광고할 제품을 추가할 수 있습니다.",
      keywords_table_title: "아직 키워드를 추가하지 않았습니다.",
      keywords_table_text:
        "광고가 표시될 키워드를 입력하여 지정할 수 있습니다.",
      categories_table_title: "아직 카테고리를 추가하지 않았습니다.",
      categories_table_text:
        "광고가 표시될 카테고리를 입력하여 지정할 수 있습니다.",
      negative_keywords_table_title: "아직 제외 키워드를 추가하지 않았습니다.",
      negative_keywords_table_text:
        "광고를 표시하지 않을 제외 키워드를 입력하여 지정할 수 있습니다.",
      negative_categories_table_title:
        "아직 제외 카테고리를 추가하지 않았습니다.",
      negative_categories_table_text:
        "광고를 표시하지 않을 제외 카테고리를 지정할 수 있습니다.",
      sync_logs_table_title: "동기화 기록 데이터 없음",
      sync_logs_table_text: "동기화 기록 데이터를 찾을 수 없습니다.",
      ad_placements_table_title: "아직 광고 배치를 추가하지 않았습니다.",
      ad_placements_table_text:
        "광고 배치 추가 버튼을 클릭하여 광고 배치를 추가할 수 있습니다.",
      creatives_table_title: "아직 크리에이티브가 추가되지 않았습니다!",
      creatives_table_text:
        "나중에 이 영역에서 추가된 크리에이티브를 볼 수 있습니다.",
      creatives_relations_table_title:
        "아직 크리에이티브를 생성하지 않았습니다.",
      creatives_relations_table_text:
        "캠페인 양식에서 새 크리에이티브를 생성할 수 있습니다.",
      creativeForm_title: "아직 크리에이티브를 추가하지 않았습니다.",
      creativeForm_text: '추천 크리에이티브 "크기":',
      invites_table_title: "아직 사용자를 초대하지 않았습니다.",
      invites_table_text:
        "새 사용자 추가 버튼을 클릭하여 사용자를 초대할 수 있습니다.",
      review_title: "현재 검토할 크리에이티브가 없습니다.",
      review_text:
        "모든 크리에이티브가 승인되었거나 새로운 크리에이티브가 없습니다. 승인 대기 중인 크리에이티브는 이 화면에 표시됩니다.",
      accepted_table_creatives_title: "승인된 크리에이티브가 아직 없습니다!",
      accepted_table_creatives_text:
        "모든 크리에이티브가 거부되었거나 새로운 크리에이티브가 없습니다.",
      rejected_table_creatives_title:
        "승인이 거부된 크리에이티브가 아직 없습니다!",
      rejected_table_creatives_text:
        "모든 크리에이티브가 승인되었거나 새로운 크리에이티브가 없습니다.",
      pending_table_creatives_title: "아직 크리에이티브가 추가되지 않았습니다!",
      pending_table_creatives_text:
        "모든 크리에이티브가 검토되었거나 새로운 크리에이티브가 없습니다.",
      blacklist_table_title: "키워드 제한을 찾을 수 없습니다.",
      blacklist_table_text:
        "새 키워드 추가 버튼을 클릭하여 제한을 추가할 수 있습니다.",
      cities_table_title: "아직 도시를 추가하지 않았습니다.",
      cities_table_text: "nan",
      age_ranges_table_title: "아직 나이 범위를 추가하지 않았습니다.",
      age_ranges_table_text: "nan",
      genders_table_title: "아직 성별을 추가하지 않았습니다.",
      genders_table_text: "nan",
      platforms_table_title: "아직 플랫폼을 추가하지 않았습니다.",
      platforms_table_text: "nan",
      customers_table_title: "아직 고객을 추가하지 않았습니다.",
      customers_table_text: "nan",
      report_loading_title: "리포트 생성 진행 중",
      report_loading_text:
        "현재 리포트를 생성 중입니다. 리포트의 복잡성과 크기에 따라 시간이 다소 걸릴 수 있습니다.",
    },
    endDatePicker: {
      label: "종료 날짜",
      campaigns: "캠페인 종료 날짜을 선택하세요.",
      reports: "리포트 종료 날짜을 선택하세요.",
      button: "종료 날짜 없음",
    },
    reportForm: {
      title: "리포트 정보",
      nameTitle: "리포트 이름",
      nameText: "리포트 테이블에 표시할 이름을 입력하세요.",
      nameSub: "리포트 이름을 입력하세요.",
      reportTypeTitle: "전달",
      reportTypeText:
        "리포트의 전달 유형을 선택하세요. 생성한 리포트는 선호도에 맞게 전달됩니다.",
      static: "고정",
      staticText: "리포트가 한 번 실행되고 리포트에 표시됩니다.",
      scheduled: "예약됨",
      scheduledText: "리포트 전송 주기와 수신자를 선택하세요.",
      frequency: "리포트의 전송 주기를 선택하세요.",
      daily: "일일",
      weekly: "주간",
      monthly: "월간",
      sendDate: "첫 리포트를 받고 싶은 날짜를 선택하세요.",
      firstDay: "매달 첫째 날",
      lastDay: "매달 마지막 날",
      exportType: "받고 싶은 리포트 형식을 선택하세요.",
      mail: "리포트가 전송될 이메일 주소를 입력하세요.",
      dateTitle: "리포트 날짜",
      dateText: "리포트의 시작 날짜와 종료날짜를 선택하세요.",
      startDateSub: "리포트 시작 날짜를 선택하세요.",
      reportKindTitle: "리포트 유형",
      reportKindText:
        "미리 정의된 리포트를 통해 성과를 쉽게 측정할 수 있습니다. 커스텀 리포트를 통해 특정기준, 측정항목 및 필터를 추가하여 커스텀 리포트를 생성할 수 있습니다.",
      predefined: "미리 정의된 리포트",
      predefinedPlaceholder: "미리 정의된 리포트 유형 선택",
      special: "커스텀 리포트",
      breaks: "측정기준",
      breaksText: "리포트에 포함할 측정기준을 선택하세요.",
      breaksPlaceholder: "차원 검색",
      maxWarning: "리포트에 최대 3개의 측정기준을 추가할 수 있습니다.",
      clear: "초기화",
      metrics: "측정항목",
      metricsText: "리포트에 포함할 측정항목을 선택하세요.",
      filters: "필터:",
      filtersText: "측정기준 및 측정항목에 적용할 필터를 선택하세요.",
      product: "제품 성과",
      product_category: "카테고리 성과",
      product_brand: "브랜드 성과",
      keyword: "키워드 성과",
      campaign: "캠페인 성과",
      advertiser: "광고주 성과",
      agency: "에이전시 성과",
      errorStates: {
        date: "날짜를 선택하세요.",
        mail: "이메일을 입력하세요.",
        type: "리포트 유형을 선택하세요.",
        metric: "적어도 하나의 지표를 선택하세요.",
      },
      dimensions: {
        campaignName: "캠페인 이름",
        campaignBidType: "입찰 유형",
        campaignBudgetType: "예산 유형",
        product: "제품",
        categoryName: "카테고리 페이지",
        keywordText: "키워드",
        productBrand: "제품 브랜드",
        productName: "제품",
        day: "일",
        week: "주",
        month: "월",
        year: "년",
        hour: "시간",
        advertiser: "광고주 ID",
        advertiserName: "광고주",
        agency: "에이전시 ID",
        agencyName: "에이전시",
        customerDeviceType: "고객 디바이스 유형",
        saleProductName: "간접 판매 제품",
        placementId: "배치 ID",
        customerType: "고객 유형",
        productRetailerTaxonomy: "제품 카테고리",
        adPlacementCustomKey: "광고 배치 ID",
        adPlacementName: "광고 배치 이름",
        placementText: "배치 텍스트",
        in_search: "검색 결과",
        in_category: "카테고리 페이지",
        in_product_detail_page: "제품 상세 페이지",
        in_home_page: "홈페이지",
        campaignAdType: "캠페인 유형",
        campaignBudget: "예산",
        campaignFixedBid: "고정 입찰 캠페인",
        keywordBid: "키워드 입찰",
        categoryBid: "카테고리 입찰",
        advertiserResourceID: "판매자 ID",
        productSKU: "제품 ID",
        customerDeviceEnvironmentType: "고객 디바이스 환경 유형",
        campaignDailyBudget: "일일 예산",
        campaignTotalBudget: "총 예산",
      },
      metric: {
        sumImpression: "노출수",
        sumViewableImpression: "조회 가능 노출수",
        sumClick: "클릭수",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "판매",
        ACoS: "ACoS",
        sumDirectSale: "직접 판매",
        sumIndirectSale: "간접 판매",
        sumDirectSaleAmount: "직접 판매 금액",
        sumIndirectSaleAmount: "간접 판매 금액",
        sumSaleAmount: "판매 금액",
        sumSpend: "지출",
        sale: "판매",
        sumVideoStart: "동영상 시작 수",
        sumVideoComplete: "동영상 완료 수",
        VCR: "동영상 완료율",
      },
    },
    selectFilter: {
      title: "필터 옵션",
      placeholder: "필터 선택",
      budgetOpt: "남은 예산",
      budgetAdvertiser: "광고주 이름",
      placeholderCondition: "조건 선택",
      bigger: "보다 큼",
      smaller: "보다 작음",
      equal: "같음",
      notEqual: "같지 않음",
      any: "포함",
      add: "필터 추가",
      placeholderValue: "값 선택",
      enter: "값 입력",
      date: "날짜 선택",
    },
    searchBar: {
      keywords: "키워드 추가...",
      negative_keywords: "키워드 추가...",
      categories: "카테고리 추가...",
      negative_categories: "카테고리 추가...",
    },
    settingsHeader: {
      general: "일반 정보",
      generalText: "마켓플레이스에 대한 일반 정보",
      integration: "통합 정보",
      integrationText: "데이터 전송에 필요한 통합 정보",
      advertiser: "광고주 설정",
      advertiserText: "광고주와 관련된 설정.",
      auction: "경매 설정",
      auctionText: "경매 관련 설정",
      campaign: "캠페인 설정",
      campaignText: "캠페인 설정과 관련된 설정",
      bid: "입찰 제한",
      bidText: "플랫폼 전체 입찰 규칙 설정",
      conversion: "전환",
      conversionText: "플랫폼의 전환 목표와 관련된 설정",
      category: "카테고리 제한",
      categoryText: "플랫폼에 표시되는 카테고리에 대한 제한",
      keyword: "키워드 제한",
      keywordText: "플랫폼에서 검색된 키워드에 대한 제한",
    },
    stepsNavbar: {
      report_create: "리포트 생성",
      campaign_create: "캠페인 생성",
      report_edit: "리포트 업데이트",
      step: "다음 단계는 {{value}}",
      advertiser: "광고주:",
    },
    suggestionCard: {
      addAll: "모두 추가",
    },
    table: {
      keywords: {
        keyword: "키워드",
        matchType: "매치 유형",
        suggested: "제안 입찰가",
        dynamic: "자동",
        volume: "검색량",
        competition: "경쟁",
        low: "1000 미만",
        medium: "1K-5K",
        high: "5K-10K",
        highest: "10K+",
        search: "키워드 추가...",
        suggestTitle: "제안된 키워드",
        suggestText: "제품에 대한 추천 키워드.",
        negativePlaceholder: "제외 키워드 추가...",
        exact: "키워드와 정확히 일치하는 용어 검색.",
        phrase: "키워드의 구성 요소를 동일한 순서로 포함하는 용어 검색.",
        broad: "키워드의 구성 요소를 임의의 순서로 포함하는 용어 검색.",
        warning: "최소 값은 {{value}}이어야 합니다.",
      },
      products: {
        productSearch: "제품, SKU 또는 바코드를 검색하세요.",
        storeSearch: "스토어를 검색하세요.",
        loading: "로딩 중",
        title: "제품을 선택하세요.",
        text: "왼쪽의 제품 목록에서 추가 버튼을 클릭하여 광고할 제품을 추가할 수 있습니다.",
        productSelected: "선택한 제품",
        storeSelected: "선택한 스토어",
        remove: "모두 제거",
        price: "가격",
        stock: "재고",
        rating: "평점",
        drawerTitle: "대량 제품 선택",
        sku: "SKU 또는 바코드 목록",
        skuText:
          "아래 필드에 추가하려는 제품 SKU 또는 바코드를 한 줄에 하나씩 추가하세요.",
        categories: "모든 카테고리",
        bulk: "대량 선택",
        all: "모두 보기",
        selected: "선택한 제품 보기",
        total: "총 <strong>{{value}}개의 제품</strong>이 있습니다.",
        variable: "변수",
      },
      categories: {
        suggestTitle: "추천 카테고리",
        suggestText: "제품에 대한 추천 카테고리",
      },
      result: {
        campaignName: "캠페인 이름",
        campaign: "캠페인 ID",
        campaignBidType: "입찰 유형",
        campaignBudgetType: "예산 유형",
        advertiserName: "광고주 이름",
        agencyName: "에이전시 이름",
        product: "제품",
        productName: "제품",
        categoryText: "카테고리",
        keyword: "키워드 ID",
        keywordText: "키워드 텍스트",
        keywordMatchType: "매치 유형",
        productBrand: "제품 브랜드",
        day: "일",
        week: "주",
        month: "월",
        year: "년",
        hour: "시간",
        sumImpression: "노출수",
        sumViewableImpression: "조회 가능 노출수",
        sumClick: "클릭수",
        CTR: "CTR",
        CVR: "CVR",
        sumSale: "판매",
        ACoS: "ACoS",
        sumDirectSale: "직접 판매",
        sumIndirectSale: "간접 판매",
        sumDirectSaleAmount: "직접 판매 금액",
        sumIndirectSaleAmount: "간접 판매 금액",
        sumSaleAmount: "판매 금액",
        sumSpend: "지출",
        campaignStartDate: "시작 날짜",
        campaignEndDate: "종료 날짜",
        campaignStatus: "상태",
        campaignBudget: "예산",
        advertiser: "광고주",
        agency: "에이전시",
        weekOf: "{{value}}의 주",
        customerDeviceType: "고객 디바이스 유형",
        saleProductName: "간접 판매 제품",
        placementId: "배치 ID",
        customerType: "고객 유형",
        categoryName: "카테고리 페이지",
        productRetailerTaxonomy: "제품 카테고리",
        adPlacementCustomKey: "광고 배치 ID",
        adPlacementName: "광고 배치 이름",
        placementText: "배치 텍스트",
        in_search: "검색 결과",
        in_category: "카테고리 페이지",
        in_product_detail_page: "제품 상세 페이지",
        in_home_page: "홈페이지",
        in_collection: "컬렉션",
        campaignAdType: "캠페인 유형",
        campaignFixedBid: "고정 입찰 캠페인",
        keywordBid: "키워드 입찰",
        categoryBid: "카테고리 입찰",
        advertiserResourceID: "판매자 ID",
        productSKU: "제품 ID",
        customerDeviceEnvironmentType: "고객 디바이스 환경 유형",
        campaignDailyBudget: "일일 예산",
        campaignTotalBudget: "총 예산",
        sumVideoStart: "동영상 시작 수",
        sumVideoComplete: "동영상 완료 수",
        VCR: "동영상 완료율",
      },
    },
    states: {
      campaigns_not_started: "캠페인이 아직 시작되지 않았습니다.",
      campaigns_date_passed: "캠페인이 예정된 종료 날짜를 지났습니다.",
      campaigns_status_paused: "캠페인을 일시 중지했습니다.",
      campaigns_out_of_daily_budget: "캠페인의 일일 예산이 소진되었습니다.",
      campaigns_out_of_total_budget: "캠페인이 총 예산 한도에 도달했습니다.",
      campaigns_products_stopped: "제품이 활성화되지 않았습니다.",
      campaigns_keywords_stopped: "키워드가 활성화되지 않았습니다.",
      campaigns_categories_stopped: "카테고리가 활성화되지 않았습니다.",
      campaigns_status_archived: "캠페인을 아카이브했습니다.",
      campaigns_creatives_stopped: "활성 크리에이티브가 없습니다.",
      campaigns_insufficient_balance: "잔액이 부족합니다.",
      campaigns_low_bid: "입찰가가 최소 입찰가보다 낮습니다.",
      campaigns_regions_stopped: "지역이 활성화되지 않았습니다.",
      products_status_paused: "제품을 일시 중지했습니다.",
      products_low_stock: "재고 제한으로 인해 상태가 중지되었습니다.",
      products_low_rating: "평점 제한으로 인해 상태가 중지되었습니다.",
      products_buybox_loser: "바이박스 제한으로 인해 상태가 중지되었습니다.",
      products_product_paused: "제품이 활성화되지 않았습니다.",
      products_product_deleted: "제품이 활성화되지 않았습니다.",
      keywords_status_paused: "키워드 상태를 일시 중지했습니다.",
      keywords_low_bid: "입찰가가 최소 입찰가보다 낮습니다.",
      categories_status_paused: "카테고리 타겟팅이 중지되었습니다.",
      categories_low_bid: "입찰가가 최소 입찰가보다 낮습니다.",
      creatives_paused: "크리에이티브 상태를 일시 중지했습니다.",
      creatives_pending: "크리에이티브가 검토 대기 중입니다.",
      creatives_rejected: "크리에이티브가 거부되었습니다.",
      creatives_note: "거부 이유:",
    },
    payment: {
      amount: '"청구"할 금액:',
      title: "결제 방법",
      subtitle: "광고 잔액은 부가세 포함 가격으로 청구됩니다.",
      wire_transfer: "계좌 이체 / EFT",
      beymen_milestone_payment: "마일스톤 결제",
      koctas_milestone_payment: "마일스톤 결제",
      bank: "은행 정보",
      helpUpper:
        "계좌 이체 또는 EFT를 통해 결제가 승인되면 이체됩니다. 승인 절차는",
      helpSecondary: "2-3 영업일이 소요될 수 있습니다.",
      ref: '참조 "번호":',
      info: "거래 내역에 참조 번호를 정확히 입력해야 정확한 거래 추적이 가능합니다.",
      tax: "부가세:",
      verifyAmount: "계정에서 인출할 금액:",
      toters_milestone_payment: "지갑에서 이체",
      product_placeholder: "스토어 검색",
      craftgate: "신용 카드",
    },
    tour: {
      prev: "뒤로",
      next: "다음",
      done: "투어 종료",
      title: "광고 플랫폼에 오신 것을 환영합니다!",
      intro:
        '플랫폼 사용 방법을 알아보려면 "시작"을 클릭하여 제품 투어를 계속하세요. 투어를 건너 뛰려면 종료 버튼을 클릭하세요.',
      menu: "메뉴 바",
      menuText: "왼쪽의 메뉴 바를 사용하여 페이지를 전환할 수 있습니다.",
      campaigns: "캠페인",
      campaignsText:
        '"캠페인" 페이지에서 광고 캠페인을 관리할 수 있습니다. 여기서 광고의 클릭수 및 판매량을 확인할 수 있습니다.',
      reports: "리포트",
      reportsText:
        "다양한 측정기준을 선택하여 광고 성과 리포트를 생성할 수 있습니다. 광고가 몇 번 노출되었는지, 어떤 검색 결과나 카테고리에 표시되었는지, 그리고 얼마나 많은 판매가 이루어졌는지를 리포트에서 확인할 수 있습니다.",
      reportsTextSub:
        "리포트는 미리 정의된 리포트를 선택하거나 직접 만들 수 있는 커스텀 리포트를 선택할 수 있습니다.",
      invoices: "송장",
      invoicesText:
        "이 섹션에서는 캠페인에 사용할 잔액, 획득한 기프트 쿠폰 및 모든 광고 비용을 확인할 수 있습니다.",
      academy: "아카데미",
      academyText: "플랫폼 사용에 필요한 정보를 여기서 찾을 수 있습니다.",
      widgets: "통계",
      widgetsText:
        '생성한 캠페인의 성과를 점진적으로 확인할 수 있습니다. "차트로 보기" 버튼을 클릭하면 광고 성과를 일별로 자세히 살펴볼 수도 있습니다.',
      table: "캠페인 테이블",
      tableText:
        "이 섹션에서는 생성한 캠페인과 그 성과를 확인할 수 있습니다. 캠페인을 원하는 대로 일시 중지할 수도 있습니다. 캠페인의 세부 정보를 보려면 캠페인 이름을 클릭하세요.",
      button: "새 캠페인 추가",
      buttonText: '첫 광고 캠페인을 만드는 "새 캠페인 추가" 버튼을 클릭하세요.',
      modal: "캠페인 유형",
      modalText: "여기에서 선택할 수 있는 광고 캠페인 유형을 볼 수 있습니다.",
      type: "스폰서 제품",
      typeText:
        "검색 결과나 카테고리 페이지 상단에 제품을 강조 표시할 수 있는 스폰서 제품 캠페인을 생성할 수 있습니다.",
    },
  },
  pages: {
    acc: {
      campaigns: {
        button: "새 캠페인 추가",
        placeholder: "캠페인에서 검색",
        errorTitle: "선택된 광고주 없음",
        errorText: "광고주를 선택하세요.",
        table: {
          standart: "자동",
          max_acos: "자동",
          fixed: "고정",
          daily: "일일",
          flexible: "일일",
          total: "총",
          budgetLimit: "{{value}}로 제한됨",
          stopped: "캠페인을 중지했습니다.",
          product: "스폰서 제품",
          display: "스폰서 디스플레이",
          store: "스폰서 스토어",
          default: "기본 뷰",
          checkUp: "점검 뷰",
        },
      },
      campaignDetails: {
        subtext: "캠페인 정보를 편집할 수 있습니다.",
        products: "제품",
        on: "활성",
        keywords: "키워드",
        negativeKeywords: "제외 키워드",
        targeting: "잠재고객",
        city: "도시",
        age: "나이",
        gender: "성별",
        platform: "플랫폼",
        customers: "고객",
        placements: "광고 배치",
        in_search: "검색 결과",
        in_category: "카테고리",
        placementPlaceholder: "광고 배치에서 검색",
        date: '시작-종료 "날짜":',
        bid: "입찰:",
        maxAcos: "% 최대 ACoS",
        fixed: "고정",
        standart: "자동",
        daily: "일일",
        total: "총",
        flexible: "일일",
        none: "없음",
        keywordsBtn: "키워드 추가",
        keywordsPlaceholder: "키워드 검색",
        keywordsSub: "광고에 표시할 키워드를 추가하세요.",
        negative_keywords_btn: "제외 키워드 추가",
        negative_keywords_placeholder: "제외 키워드에서 검색",
        negative_keywords_modal_subtitle:
          "광고에 표시하지 않을 키워드를 추가하세요.",
        negative_categories_btn: "제외 카테고리 추가",
        negative_categories_placeholder: "제외 카테고리에서 검색",
        negative_categories_modal_subtitle:
          "광고에 표시하지 않을 카테고리를 추가하세요.",
        productsBtn: "제품 추가",
        productsPlaceholder: "제품 검색",
        productsSub: "광고할 제품을 선택하세요.",
        cities_placeholder: "도시 검색",
        age_ranges_placeholder: "나이 범위 검색",
        genders_placeholder: "성별 검색",
        platforms_placeholder: "플랫폼 검색",
        customers_placeholder: "고객 유형 검색",
        new: "신규 고객",
        present: "기존 고객",
        keywordTitle: "키워드 타겟팅",
        keywordText:
          "키워드는 광고를 위해 자동으로 타겟으로 지정됩니다. 타겟으로 하지 않을 키워드를 중지할 수 있습니다.",
        maxAcosLabel: "최대 ACoS",
        fixedBidLabel: "고정 입찰",
        productsError: "적어도 1개의 제품이 활성화되어야 합니다.",
        keywordsError: "적어도 1개의 키워드가 활성화되어야 합니다.",
        categoriesError: "적어도 1개의 카테고리가 활성화되어야 합니다.",
        categories: "카테고리",
        categoriesBtn: "카테고리 추가",
        categoriesPlaceholder: "카테고리에서 검색",
        categoriesSub: "타겟할 카테고리를 추가할 수 있습니다.",
        negativeCategories: "제외 카테고리",
        buyboxError: "제품은 바이박스 우승자가 아닙니다.",
        ratingError: "제품 평점이 최소 평점보다 낮습니다.",
        stockError: "제품 재고가 최소 재고보다 낮습니다.",
        notActiveError: "제품이 활성화되지 않았습니다.",
        creatives: "크리에이티브",
        creativesBtn: "크리에이티브 추가",
        creativesPlaceholder: "크리에이티브 검색",
        creativesSub: "광고에 표시할 크리에이티브를 추가하세요.",
        creativesLinkTitle: "리디렉션 URL",
        creativesLinkText: "광고를 클릭한 사용자는 {{value}}로 이동합니다.",
        winner: "승자",
        loser: "패자",
        creativesError: "최소한 1개의 크리에이티브가 활성화되어야 합니다.",
        pendingError: "대기 중인 크리에이티브는 활성화될 수 없습니다.",
        rejectedError: "거부된 크리에이티브는 활성화될 수 없습니다.",
        in_product_detail_page: "제품 상세 페이지",
        in_home_page: "홈페이지",
        in_collection: "컬렉션",
        region: "지역:",
        regionLength: "{{value}} 지역",
        regionsDrawerTitle: "지역 업데이트",
        languages: "{{value}} 언어",
      },
      noMatch: {
        title: "죄송합니다. 해당 페이지를 찾을 수 없습니다.",
        btn: "홈으로 돌아가기",
      },
      reports: {
        button: "새 리포트 추가",
        placeholder: "리포트에서 검색",
        name: "리포트 이름",
        table: {
          type: "리포트 유형",
        },
        form: {
          title: "리포트 1",
          goBack: "뒤로가기",
          create: "리포트 생성",
        },
      },
      reportDetails: {
        result: "리포트 결과",
        static: "고정",
        scheduled: "예약",
        ready: "미리 정의된 리포트",
        startDate: "시작 날짜",
        endDate: "종료 날짜",
        frequency: "빈도",
        firstDate: "첫 게시 날짜",
        month: "월의 첫 날",
        emails: "이메일",
        export: "파일 유형",
        predefined: "미리 정의된 리포트 유형",
        placeholderText:
          "캠페인 이름 / 하나 이상 / Micro USB 충전 케이블 캠페인",
        placeholderTextTwo: "플랫폼 / 하나 이상 / 모바일, 데스크탑",
        product_one: "Micro USB 2.0A 빠른 충전 고속 데이터 전송",
        product_two: "Micro USB 2A 충전 및 데이터 케이블 100 cm",
        product_three:
          "C107 Micro USB 빠른 충전 및 데이터 케이블 1mt 3.0A 18W 화이트",
        product_four: "SYX-C89 삼성 2 Mah Micro USB 케이블",
        product_five:
          "Baseus CAMKLF-BG1 Cafule Micro USB 2.4A 케이블 1 mt - 블랙",
        campaignData: "Micro USB 충전 케이블 캠페인",
      },
      invoice: {
        balance: "잔고",
        coupon: "쿠폰",
        lastPayment: "마지막으로 로드된 잔액",
        searchPlaceholder: "송장에서 검색",
        duplicateCouponsError:
          "다중 쿠폰 정의가 감지되었습니다. 잠시 기다려주세요.",
        multipleTopUpsError:
          "다중 잔액 업로드가 감지되었습니다. 잠시 기다려주세요.",
        limitError: "고액 잔액을 추가하려고 합니다. 금액을 확인하세요.",
        minAmountError: "최소 결제 금액은 {{value}}이어야 합니다.",
        data: {
          typeBudget: "잔액 결제",
          typeAd: "광고 비용",
          typeBudgetExplanation: "광고 잔액이 로드되었습니다.",
          typeAdExplanation: "광고 비용이 사용되었습니다.",
        },
        table: {
          type: "거래 유형",
        },
        modal: {
          button: "잔액 추가",
          title: "잔액 추가",
          subtitle: "광고 잔액으로 이체할 금액을 입력하세요.",
          loadingAmount: "금액",
          differentAmount: "금액을 입력하세요.",
          label: "금액",
        },
      },
      academy: {
        more: "더 알아보기",
        copy: "링크 복사",
        helpTitle: "여전히 궁금한 점이 있으신가요?",
        helpText: "질문이 있으시면 이메일로 문의해 주세요.",
        contact: "문의하기",
        highlights: "하이라이트",
        read: "나머지 기사 읽기",
        description:
          "{{value}} 광고 가이드의 유용한 문서를 통해 플랫폼에 대한 모든 것을 배우고 쉽게 광고 게재를 시작할 수 있습니다.",
      },
    },
    admin: {
      advertisers: {
        updateSubtext: "광고주를 에이전시에 할당하여 그룹화할 수 있습니다.",
        modalPlaceholder: "에이전시 검색...",
        search: "광고주 검색",
        select_advertiser_subtitle:
          "어떤 광고주를 위해 캠페인을 만들고 싶으신가요?",
        userSubtext: "광고주 계정을 사용할 사용자를 초대할 수 있습니다.",
        help: "사용자의 이메일 주소를 추가하세요.",
        deleteSuccess: "에이전시가 성공적으로 제거되었습니다.",
        create: "새로운 광고주 추가",
        update: "광고주 업데이트",
        type: '광고주 "유형":',
        custom: "커스텀",
        customText:
          "플랫폼 내에서 공유가 가능하도록 설정된 공급업체의 제품으로 만들어진 광고주 유형입니다.",
        name: "광고주 이름",
        nameHelp: "당신이 표시할 광고주의 이름입니다.",
        agency: "에이전시",
        agencyHelp: "광고주가 소속된 에이전시입니다.",
        email: "이메일",
        emailHelp:
          "초대장이 전송될 이메일 주소를 입력하세요. 판매자는 플랫폼에 로그인할 때 입력한 이메일 주소를 사용합니다.",
        product: "제품 이름",
        brand: "브랜드",
        retailer_taxonomy: "제품 카테고리",
        advertiser_id: "광고주 ID",
        filters: "필터:",
        filterWarning: "적어도 하나 이상의 필터를 추가하세요.",
        typeTitle: "광고주 유형",
        typeSubtext: "위에서 선택한 광고주 유형을 어떻게 변경하시겠습니까?",
        default: "기본",
        defaultText:
          "플랫폼 내에서 자신의 이름으로 직접 판매하는 판매자 계정에 사용됩니다.",
        shareable: "공유 가능",
        shareableText:
          "플랫폼 내에서 타인을 대신하여 판매하는 판매자 계정에 사용되며, 메인 판매자에게 배포되어야 합니다.",
        generalTitle: "일반 정보",
        generalSubtext: "광고주 일반 정보",
        successText:
          "선택한 판매자의 브랜드와 일치하는 모든 제품이 추가되었습니다.",
        advertiser: "광고주:",
        drawerFilterWarning: "추가한 필터는 되돌릴 수 없습니다!",
        filterTitle: "필터",
        keyLabel: "필터 선택",
        opLabel: "조건 선택",
        valueLabel: "값 입력",
        categorySelectLabel: "카테고리 선택",
        filterKeyError: "필터를 선택하세요.",
        filterOpError: "조건을 선택하세요.",
        filterValueError: "값을 입력하세요.",
        filterCategoryError: "카테고리를 선택하세요.",
      },
      advertiserSettings: {
        title: "광고주 설정",
        subtext: "광고주와 관련된 설정",
        status: "기본 광고주 상태",
        statusText:
          "플랫폼에 추가된 광고주의 기본 상태가 닫혔으면, 광고주 페이지에서 상태를 활성화하여 시스템에 로그인할 수 있도록 하세요.",
        welcome: "웰컴 쿠폰",
        welcomeText:
          "광고주가 플랫폼에 처음으로 로그인할 때 사용할 수 있는 웰컴 쿠폰을 정의할 수 있습니다.",
        warning: "0을 입력하면 광고주에게는 웰컴 쿠폰이 정의되지 않습니다.",
        label: "쿠폰 잔액",
        language: "언어",
        languageText:
          "광고주의 기본 인터페이스 언어입니다. 광고주는 자체 인터페이스를 통해 언어 선택을 변경할 수 있습니다.",
        balance: "잔액 로드 권한",
        balanceText:
          "광고주가 인터페이스를 통해 진행 금액을 광고 잔액으로 이체할 수 있는 권한입니다.",
        open: "열기",
        closed: "닫힘",
        error_title: "값을 입력하세요.",
        error_text:
          '쿠폰을 정의하고 싶지 않으면 "0"을 입력한 후 저장 버튼을 클릭하세요.',
        error_text_input: "값을 입력하세요.",
      },
      agencies: {
        label: "에이전시 이름",
        subtext: "에이전시 이름을 입력하세요",
        subtitle: "에이전시를 추가하여 광고주를 그룹화할 수 있습니다.",
        search: "에이전시에서 검색",
        edit: "에이전시 이름 업데이트",
      },
      auctionSettings: {
        title: "경매 설정",
        subtext: "경매와 관련된 설정",
        type: "경매 유형",
        typeText:
          "광고주의 입찰이 평가될 경매 유형을 선택하세요. 경매 유형에 대한 자세한 정보는 여기에서 찾을 수 있습니다.",
        warning: "변경 사항이 플랫폼에서의 광고 지출에 영향을 미칩니다.",
        firstOpt: "첫 번째 가격",
        firstOptText: "낙찰한 광고주가 입찰한 가격을 지불합니다.",
        secondOpt: "두 번째 가격",
        secondOptText:
          "낙찰한 광고주가 두 번째로 높은 입찰가보다 1센트 더 많이 지불합니다.",
        link: "경매 유형",
      },
      campaignSettings: {
        title: "캠페인 설정",
        subtext: "캠페인 설정 관련 설정",
        transformation: "전환 범위",
        transformationText:
          "사용자의 구매 활동이 광고 클릭에 기여될 시간 범위를 지정할 수 있습니다. 전환 범위를 7일로 설정하면 사용자가 광고를 클릭하고 7일 이내에 제품을 구매할 경우 캠페인 잔액이 차감됩니다.",
        product: "제품 평가",
        productText:
          "광고주가 캠페인에 추가할 제품에 대한 최소 평가 요구 사항을 추가하고 이 조건을 충족하지 못하는 제품에 대한 동작을 커스텀할 수 있습니다.",
        minimum: "최소 제품 평가",
        minimumText: "캠페인에 추가 될 제품의 최소 제품 등급을 입력하세요.",
        behaviour: "동작 설정",
        behaviourText: "캠페인에 추가 될 제품의 최소 제품 등급을 입력하세요.",
        placeholder: "동작 선택",
        stock: "제품 재고",
        stockText:
          "광고주가 캠페인에 추가할 제품에 대한 최소 재고 요구 사항을 추가하고 이 조건을 충족하지 못하는 제품에 대한 동작을 커스텀할 수 있습니다.",
        minimumStock: "최소 제품 재고",
        minimumStockText: "캠페인에 추가 될 제품의 최소 재고를 입력하세요.",
        stockBehaviourText:
          "재고 수가 최소 재고보다 적어질 때 발생할 동작을 결정하세요.",
        alert: "경고만",
        block: "경고 및 광고 차단",
        targeting: "인구 특성 타겟팅",
        targetingText:
          "캠페인 설정에서, 인구 특성 타겟팅 단계를 활성화할지 여부를 선택하세요. 활성화되면 캠페인 설정에 추가 단계가 추가되어 광고주가 타겟팅 환경 설정을 할 수 있습니다.",
        all: "모든 광고주",
        none: "광고주 없음",
        selected: "선택된 광고주",
        format: "광고 형식",
        sponsored: "스폰서 제품",
        brand: "스폰서 브랜드",
        ad: "시각적 광고",
        rating_warn:
          "제품 평가가 너무 낮습니다! 이 제품을 광고에 추가하면 성과가 저하됩니다.",
        rating_block:
          "제품 평가가 너무 낮습니다! 이 제품을 광고에 추가할 수 없습니다!",
        stock_warn:
          "재고가 너무 낮습니다! 이 제품을 광고에 추가하면 성과가 저하됩니다.",
        stock_block:
          "귀하의 재고가 너무 낮습니다! 이 제품을 광고에 추가할 수 없습니다!",
        buybox_warn:
          "제품이 바이박스 우승자가 아닙니다. 이 제품을 광고에 추가하면 성과가 저하됩니다.",
        buybox_block:
          "제품이 바이박스 우승자가 아닙니다. 이 제품을 광고에 추가할 수 없습니다!",
        buyBoxTitle: "바이박스 조건",
        buyBoxText:
          "광고주가 캠페인에 추가 할 제품에 대한 바이박스 우승자 조건을 추가 할 수 있습니다.",
        buyBoxSubtext:
          "캠페인에 추가 될 제품이 바이박스 우승자가 아닐 때 발생하는 동작을 결정하세요.",
        noWorning: "없음",
        warn: "변경 사항은 모든 활성 캠페인에 적용됩니다.",
        rangeError: "값을 입력해야합니다. 권장 값은 7 일입니다.",
        rangeErrorInput: "1-14 일 사이의 값 만 입력할 수 있습니다.",
        chooseBehaviour: "동작을 선택해야합니다.",
        stockError: "최소 재고를 입력해야합니다.",
        allStockError: "최소 재고를 입력하고 동작을 선택해야합니다.",
        ratingError: "최소 평가를 입력해야합니다.",
        allRatingError: "최소 평가를 입력하고 동작을 선택해야합니다.",
        serverBlock: "경고 및 광고 차단",
        advertisers: "선택된 광고주",
        advertisersText:
          "인구 특성 타겟팅은 선택한 광고주에게만 사용할 수 있습니다.",
        advertisersBtn: "광고주 추가",
        advertisersSub: "인구 특성 타겟팅을 사용할 수있는 광고주를 선택하세요.",
        advertisersPlaceholder: "광고주 선택",
        advertisersLength: "{{value}} 광고주가 선택되었습니다.",
        advertisersTitle: "광고주",
        general: "일반",
        bid: "입찰 제한",
        conversion: "전환",
        conversionSubtext: "플랫폼에서 전환 목표와 관련된 설정",
        biddingTypes: "스폰서 제품 이용 가능 입찰 유형",
        biddingTypesText:
          "캠페인 생성 단계에서 광고주가 사용할 수있는 입찰 유형을 지정할 수 있습니다.",
        biddingTypesWarning: "적어도 하나의 옵션이 열려 있어야합니다.",
        auto: "자동 입찰",
        autoText:
          "입찰이 자동으로 결정되는 입찰 유형입니다. 지출은 판매된 제품의 가격과 설정한 타겟 ACoS를 사용하여 계산됩니다.",
        acos: "타겟 ACoS",
        acosText:
          "광고주가 입력한 ACoS 비율과 판매된 제품의 가격을 기반으로 입찰이 자동 계산됩니다.",
        fixed: "고정 입찰",
        fixedText:
          "광고주에 의해 입찰이 결정됩니다. 광고주는 키워드 및 범주 당 캠페인 전체 입찰을 설정할 수 있습니다.",
        bidWarning: "적어도 하나의 입찰 유형을 선택해야합니다.",
        bidText: "플랫폼 전체 입찰 규칙에 대한 설정",
        bidLabelTitle: "총 최소 CPC",
        bidLabelText:
          "모든 카테고리와 키워드에 입력해야 하는 클릭 당 최소 입찰 금액입니다.",
        bidLabel: "최소 CPC",
        cpmTitle: "총 최소 CPM",
        cpmText:
          "모든 카테고리와 키워드에 입력해야 하는 노출 당 최소 입찰 금액입니다.",
        cpmLabel: "최소 CPM",
        targetAcosTitle: "일반 타겟 ACoS",
        targetAcosText:
          "경매에서 기본으로 설정할 수 있는 ACoS의 최대 백분율을 설정할 수 있습니다.",
        targetAcosLabel: "최대 ACoS",
        categoryRelevanceTitle: "일반 카테고리 관련성",
        categoryRelevanceText:
          "결제 광고 상품과 유기적으로 나열된 상품 간 광고 노출 간의 관련성 수준을 결정합니다.",
        categoryRelevanceLabel: "카테고리 관련성",
        bidTabWarning:
          "변경 사항은 플랫폼의 광고 비용과 노출에 영향을 미칩니다.",
        inputErrorZero: "0보다 큰 값을 입력해야 합니다.",
        inputErrorAcos: "0에서 100 사이의 값을 입력해야 합니다.",
        inputErrorRelevance: "0보다 큰 정수를 입력해야 합니다.",
        inputError: "값을 입력해야 합니다.",
        productLink: "제품 요금 제한",
        stockLink: "제품 재고 제한",
        targetingLink: "인구 통계 타게팅 기능",
        bidLink: "입찰 유형",
        minThreshold: "최소 판매 기준값",
        minThresholdText:
          "이것은 제품이 경매에 포함되기 위한 최소 판매 기준값입니다.",
        minThresholdLabel: "최소 판매 기준값",
        minThresholdError: "정수를 입력해주세요.",
      },
      coupons: {
        add: "쿠폰 정의",
        title: "광고주 정보",
        subtext: "광고주가 캠페인 비용으로 사용할 쿠폰을 정의할 수 있습니다.",
        label: "만료 날짜",
        warning:
          "광고주는 입력한 날짜까지 쿠폰을 사용해야 합니다. 만료 날짜를 입력하지 않으면 쿠폰은 광고주의 잔액에 영구적으로 추가되며 광고주는 원하는 시점에 쿠폰을 사용할 수 있습니다.",
        gift_coupon: "기프트 쿠폰",
        welcome_coupon: "웰컴 쿠폰",
        gift_coupon_desc: "기프트 쿠폰이 정의되었습니다.",
        welcome_coupon_desc: "웰컴 쿠폰이 정의되었습니다.",
        approve: "쿠폰을 계속 정의하시겠습니까?",
        note: "쿠폰 설명",
        noteHelp: "쿠폰을 정의하는 이유를 작성하세요. 예: 블랙 프라이데이 쿠폰",
      },
      creatives: {
        all: "모두",
        accepteds: "승인됨",
        rejecteds: "거절됨",
        pendings: "대기 중",
        pending: "대기 중",
        accepted: "승인됨",
        rejected: "거절됨",
        adModalTitle: "광고 미리보기",
        creativeModalTitle: "크리에이티브 미리보기",
        creative: "크리에이티브",
        name: "크리에이티브 이름",
        search: "크리에이티브에서 검색",
        rejectSubtitle:
          "창의를 거부하는 이유를 입력하거나 비워두고 계속할 수 있습니다.",
        note: "창의 거부 이유를 입력하세요.",
      },
      generalInfo: {
        title: "일반 정보",
        subtext: "마켓플레이스에 관한 일반 정보",
        market: "마켓플레이스 이름",
        marketText:
          "인터페이스에 표시되는 스토어 이름입니다. 이 정보를 변경하려면 계정 관리자에게 문의해야 합니다.",
        url: "URL",
        urlText:
          "인터페이스 및 라우팅에 사용될 스토어의 링크입니다. 이 정보를 변경하려면 계정 관리자에게 문의해야 합니다.",
        country: "국가",
        countryText:
          "도시 타게팅에 사용될 국가입니다. 이 정보를 변경하려면 계정 관리자에게 문의해야 합니다.",
        zone: "시간대",
        zoneText:
          "인터페이스에서 사용할 기본 시간대입니다. 이 정보를 변경하려면 계정 관리자에게 문의해야 합니다.",
        currency: "통화",
        currencyText:
          "인터페이스에서 사용할 통화입니다. 이 정보는 변경할 수 없습니다.",
        zoneIstanbul: "유럽/이스탄불",
        email: "고객상담 이메일 주소",
        emailText: "판매자가 연락할 이메일 주소입니다.",
        emailLabel: "이메일",
        emailError: "이메일을 입력해야 합니다.",
        seller: "판매자 패널",
        sellerText: "인터페이스를 통해 판매자를 직접 패널로 안내할 주소입니다.",
        sellerLabel: "판매자 패널",
        marketError: "마켓플레이스 이름을 입력해야 합니다.",
        urlError: "URL을 입력해야 합니다.",
      },
      inspect: {
        campaign: "캠페인:",
        advertiser: "광고주:",
        url: "URL:",
        size: "크기:",
        status: "상태:",
        keywords: "키워드",
        categories: "카테고리",
        languages: "언어",
      },
      integrationInfo: {
        title: "통합 정보",
        subtext: "데이터를 전송하기 위해 필요한 통합 정보",
        api: "API 보안 코드",
        apiText: "API를 통해 플랫폼에 액세스하기 위해 필요한 보안 코드입니다.",
        ftp: "FTP 보안 인증서",
        ftpText: "FTP 서버에 액세스하기 위해 필요한 인증서입니다.",
        download: "다운로드",
        copy: "클릭하여 복사",
        copied: "복사됨",
        auto: "자동 노출",
        autoText:
          "이 설정은 이벤트 통합 없이 자동으로 모든 노출을 기록할 수 있습니다. 설정이 활성화되어 있는 경우 광고 응답에 포함된 모든 제품이 사이트에 표시된 것으로 가정되어 각 제품에 대한 조회 지표가 기록됩니다. 설정이 비활성화되어 있는 경우 각 표시마다 원하는 형식에 따라 이벤트 요청을 보내야 합니다.",
        link: "노출 이벤트",
        apiLink: "API 엔드포인트",
      },
      transactions: {
        subtext: "광고주가 캠페인 지출에 사용할 잔액을 정의할 수 있습니다.",
        warning: "송장 번호를 추가할 수 있습니다.",
        balance_updated: "잔액 결제",
        gift_coupon: "기프트 쿠폰",
        welcome_coupon: "웰컴 쿠폰",
        refund: "환불",
        spend: "광고 비용",
        ad_balance_loaded_desc: "광고 잔액이 로드되었습니다.",
        spend_desc: "광고 비용이 소비되었습니다.",
        gift_coupon_desc: "기프트 쿠폰을 받았습니다.",
        welcome_coupon_desc: "웰컴 쿠폰을 받았습니다.",
        ad_balance_refunded_desc: "광고 잔액이 환불되었습니다.",
        credit_card_desc: "신용카드를 통해 잔액이 업데이트되었습니다.",
        detailsSubtext: "광고 비용에 대한 세부 정보를 볼 수 있습니다.",
        spendTab: "광고 비용",
        budgetTab: "예산 자원",
        advertiser_budget: "잔액",
        approve: "잔액 추가를 계속하시겠습니까?",
        advertiser: "광고주:",
        balance: '"로드될" 잔액:',
        update: "송장 번호를 추가하거나 편집할 수 있습니다.",
        title: "송장 정보",
        payment: "결제",
        success: "성공",
        waiting: "대기 중",
        failure: "실패",
        invoiceWarning: "송장 번호를 최대 30자까지 입력할 수 있습니다.",
        note: "잔액 업로드 노트",
        noteHelp: "이 잔액을 업로드하는 이유를 포함하십시오. 예: 8월 잔액",
      },
      users: {
        label: "이메일",
        subtext: "초대를 위한 확인 메일이 발송됩니다.",
        invite: "초대가 전송될 이메일 주소를 입력하세요.",
        warn: "자신의 계정을 중지할 수 없습니다.",
        error: "기존 이메일 주소를 추가했습니다.",
        search: "사용자에서 검색",
        select: "추가하려는 사용자 유형을 선택하세요.",
        advertiser: "광고주",
        advertiserText:
          "선택한 광고주 계정만 접근할 수 있으며, 광고 활동을 수행하는 사람입니다.",
        marketplace: "관리자",
        marketplaceText:
          "광고 플랫폼에서 모든 권한을 가지고 있으며, 사용자에 대한 접근을 부여하거나 관리할 수 있습니다.",
        limited: "제한된 액세스",
        full: "전체 액세스",
      },
      invited: {
        error: "동일한 메일 계정에 최대 10분마다 초대를 보낼 수 있습니다.",
        success: "사용자가 다시 초대되었습니다.",
        search: "초대된 사용자에서 검색",
        title: "초대 세부 사항",
        info: "일반 정보",
        mail: "이메일:",
        advertiser: "광고주:",
        urlTitle: "초대 링크",
        link: "광고주 사용자를 추가하는 방법?",
        single: "단일 사용자",
        multiple: "대량 사용자",
        advertiserText: "광고주 선택.",
        draggerTitle:
          "대량 이메일을 업로드하려면 <strong>.CSV</strong> 파일을 선택하",
        draggerText: "거나 여기로 드래그하여 놓으세요.",
        csvError: "파일 업로드 중 오류가 발생했습니다.",
        csvInfo:
          "CSV 파일을 사용하여 여러 광고주 계정에 사용자를 대량으로 초대할 수 있습니다. CSV 파일 형식에 대한 자세한 정보는 아래 링크에서 확인할 수 있습니다.",
        restriction: "제한된 액세스",
        restrictionText:
          "이 옵션을 켜면 사용자에게 보고 전용 권한이 부여됩니다.",
        edit: "편집",
        role: "역할:",
        MARKETPLACE: "관리자",
        MARKETPLACE_REPORTER: "관리자 리포터",
        ADVERTISER: "광고주",
        ADVERTISER_REPORTER: "광고주 리포터",
      },
      sync: {
        file: "파일",
        api: "API",
        feed: "피드",
        products_created: "제품이 생성되었습니다.",
        products_updated: "제품이 업데이트되었습니다.",
        products_deactivated: "제품이 비활성화되었습니다.",
        products_activated: "제품이 활성화되었습니다.",
        missing_advertisers: "광고주가 없음.",
        advertisers_created: "광고주가 생성되었습니다.",
        advertisers_updated: "광고주가 업데이트되었습니다.",
        advertisers_deactivated: "광고주가 비활성화되었습니다.",
        advertisers_activated: "광고주가 활성화되었습니다.",
        categories_created: "카테고리가 생성되었습니다.",
      },
      placement: {
        add: "새 광고 배치 추가",
        display: "디스플레이",
        product: "스폰서 제품",
        update: "광고 배치 업데이트",
        settings: "일반 설정",
        settingsText: "광고 배치의 일반 설정",
        name: "광고 배치 이름",
        nameText: "이 이름은 당신만 볼 수 있습니다.",
        format: "광고 형식",
        formatText: "광고 배치가 표시될 형식",
        sponsoredText: "관련 키워드 및 카테고리에서 제품 표시",
        displayText: "관련 키워드 및 카테고리에서 시각적 광고 표시",
        suggestedTitle: "권장 사이즈",
        suggestedText:
          "광고 배치의 실제 크기입니다. 이 크기는 광고주에 의해 표시됩니다.",
        suggestedError: "최대 1 사이즈까지 추가할 수 있습니다.",
        acceptedTitle: "허용되는 사이즈",
        acceptedText:
          "광고 배치 내에 나타날 수 있는 다른 광고 크기입니다. 광고주가 크기를 지정합니다.",
        acceptedError: "광고 크기를 선택하세요.",
        formatError: "형식을 선택하세요.",
        cross_sell: "교차 판매",
        crossText:
          "관련 제품 상세 페이지에 광고를 표시하거나 나열된 제품을 광고로 표시하는 것이 방지됩니다.",
        max_ads: "최대 광고 수",
        maxText: "배치할 수 있는 최대 광고 수.",
        crossTitle: "광고 차단 동작",
        crossSub:
          "광고 배치의 실제 크기입니다. 이 크기는 광고주에게 표시됩니다.",
        crossPlaceholder: "유형을 선택하세요.",
        crossProduct: "나열된 제품 차단",
        crossBlock: "광고 차단",
        crossError: "동작을 선택하세요.",
        targeting: "타겟팅",
        targetingText: "광고 위치를 타겟팅할 배치 그룹을 선택하세요.",
        typeError: "타겟팅 옵션을 선택하세요.",
        targetingPlaceholder: "타겟 배치",
        search: "검색 결과",
        category: "카테고리 페이지",
        homepage: "홈페이지",
        detail: "제품 상세 페이지",
        collection: "컬렉션",
        videoWarning:
          "비디오 광고의 최대 광고 수는 <strong>“1”</strong>입니다.",
        promoteProducts: "제품 홍보",
        promoteText:
          "제품 프로모션이 활성화되면 광고를 하나만 추가할 수 있습니다.",
      },
      restrictions: {
        category: "카테고리",
        keyword: "키워드",
        categoryTitle: "카테고리 기반 입찰 & ACoS",
        categoryText:
          "지정된 카테고리에서만 유효한 최소 입찰가와 목표 ACoS를 입력하고, 카테고리 관련성을 커스텀 설정할 수 있습니다. 이 필드에서 설정한 내용은 일반 설정보다 우선 적용됩니다.",
        keywordTitle: "키워드 블랙리스트",
        keywordText:
          "지정된 키워드를 사용하여 광고 표시를 제한할 수 있습니다. 독점형 제한을 설정하면 해당 키워드를 포함한 제품만 광고됩니다. 반대로, 차단형 제한을 설정하면 해당 키워드를 포함한 검색 결과에서는 광고가 표시되지 않습니다.",
        placeholder: "키워드 검색",
        add: "새 키워드 추가",
        info: "일반 정보",
        label: "키워드",
        help: "검색 결과를 제한할 키워드",
        type: "제한 유형",
        typeText: "키워드의 검색 결과에 적용되는 제한 유형",
        exclusive: "독점형",
        exclusiveText: "검색 결과에서 해당 키워드가 포함된 제품만 광고됩니다.",
        block: "차단형",
        blockText: "모든 광고가 검색 결과에서 차단되었습니다.",
      },
    },
    auth: {
      title: "{{value}} 광고 플랫폼",
      accounts: {
        title: "계정 선택",
        text: "로그인하려는 계정을 선택하세요.",
        logout: "로그아웃",
      },
      login: {
        title: "로그인",
        text: "다시 환영합니다! 정보를 입력해 주세요.",
        remember: "아이디 기억하기",
        forget: "비밀번호를 잊으셨나요?",
        button: "로그인",
        mail: "이메일",
        password: "비밀번호",
        mailErr: "이메일을 입력해주세요.",
        passwordErr: "비밀번호를 입력해주세요.",
        incorrectPassword: "비밀번호가 잘못되었습니다!",
        incorrectPasswordText: "다시 시도해주세요.",
        notActive: "귀하의 계정은 정지되었습니다.",
        notActiveText: "지원팀에 문의하세요.",
        multipleAccountErr: "새 계정을 생성하는 중 오류가 발생했습니다.",
        notHaveAnAccount: "기존 계정이 없으시다구요?",
        signup: "가입",
      },
      signUp: {
        title: "가입",
        text: "어서오세요 {{value}}님.",
        password: "비밀번호",
        passwordErr: "비밀번호를 입력하세요.",
        warning:
          "숫자, 구두점, 대문자 및 소문자를 포함한 8 자 이상의 비밀번호를 입력하세요.",
        already: "기존 계정이 있으신가요?",
        successBtn: "로그인",
        success: "登録しました。",
        successText:
          "{{value}} 메일로의 회원 가입이 성공적으로 완료되었습니다. 패널로 계속하려면 로그인 버튼을 클릭하세요.",
        help: "어떻게 도와 드릴까요?",
        err: "유효한 비밀번호를 입력하세요.",
        userExistErr:
          "이미 존재하는 사용자입니다. 액세스할 수 있는 계정을 확인하려면 로그인하세요.",
        privacyErr: "가입을 완료하려면 필요한 모든 동의 사항에 동의해 주세요.",
        error: "초대장을 찾을 수 없습니다.",
        errorText:
          "회원가입하려면 초대장이 필요합니다. {{value}}을(를) 통해 귀하의 계정 관리자에게 문의하세요.",
        errorTextNoEmail:
          "회원가입하려면 초대장이 필요합니다. 계정 관리자에게 문의하세요.",
        errorBtn: "홈페이지로 돌아가기",
      },
      password: {
        title: "비밀번호를 잊으셨나요?",
        text: "비밀번호 재설정 링크를 보내드립니다. 플랫폼에서 사용하는 이메일 주소를 입력하세요.",
        mail: "이메일",
        mailInputErr: "이메일 주소를 입력하세요.",
        reset: "비밀번호 재설정",
        return: "로그인 페이지로 이동",
        error: "비밀번호를 재설정하려는 이메일 주소를 입력하세요.",
        check: "이메일을 확인하세요",
        message: "{{value}}로 비밀번호 재설정 링크를 보냈습니다.",
      },
      newPassword: {
        title: "새 비밀번호 설정",
        text: "새 비밀번호는 이전에 사용한 비밀번호와 다르게 설정해야합니다.",
        help: "적어도 8자 이상의 비밀번호를 입력하세요. 숫자, 구두점, 대문자 및 소문자를 포함해야 합니다.",
        password: "비밀번호",
        passwordInputErr: "비밀번호를 입력하세요.",
        again: "비밀번호를 다시 입력하세요",
        check: "비밀번호 재설정이 완료되었습니다",
        message: "새로 설정한 비밀번호로 로그인할 수 있습니다.",
        errTitle: "유효한 비밀번호를 입력하세요.",
      },
      welcome: {
        title: "어서오세요！",
        loginText:
          "계정이 있으면 로그인 버튼을 클릭하여 정보를 입력할 수 있습니다.",
        loginBtn: "로그인",
        signupText:
          "아직 계정이 없으면 아래 버튼을 클릭하여 등록하고 비밀번호를 설정한 후 계정에 로그인할 수 있습니다.",
        signupBtn: "가입",
      },
    },
  },
  common: {
    save: "저장",
    selectAll: "모두 선택",
    search: "검색",
    edit: "편집",
    export: "내보내기",
    remove: "삭제",
    add: "추가",
    column: "열",
    archive: "아카이브",
    removeArchive: "아카이브 해제",
    delete: "삭제",
    filter: "필터",
    continue: "계속",
    cancel: "취소",
    cancellation: "취소",
    okay: "확인",
    create: "생성",
    goBack: "돌아가기",
    listed: "나열됨",
    archived: "아카이브됨",
    invited: "초대된 사람들",
    exact: "정확히 일치",
    phrase: "구문 일치",
    broad: "넓은 범위 일치",
    turkey: "터키",
    abd: "미국",
    try: "터키 리라",
    usd: "미국 달러",
    iqd: "이라크 디나르",
    twd: "신 대만 달러",
    hkd: "홍콩 달러",
    krw: "대한민국 원",
    jpy: "일본 엔",
    zone: "유럽/이스탄불",
    clear: "지우기",
    completed: "완료됨",
    notCompleted: "완료되지 않음",
    now: "지금",
    checkAll: "모두 선택",
    keyword: "키워드",
    negative: "제외 키워드",
    tr: "터키어",
    en: "영어",
    day: "일",
    invite: "초대",
    accept: "수락",
    reject: "거부",
    pageTotal: "표시할 행 수:",
    addUser: "사용자 추가",
    reinvite: "다시 초대",
    advertiser: "광고주",
    admin: "관리자",
    new_customer: "새로운 고객",
    existing_customer: "기존 고객",
    category_buyer: "카테고리 구매자",
    invite_url: "초대 링크 보기",
    copy: "사본 생성",
    or: "또는",
    exit: "출구",
    apply: "적용",
    languages: {
      arabic: "아랍어",
      english: "영어",
    },
    table: {
      name: "이름",
      status: "활성",
      state: "상태",
      agency_name: "에이전시",
      type: "광고주 유형",
      resource_id: "판매자 ID",
      last_login: "마지막 로그인 날짜",
      balance: "잔액",
      sum_coupons_amount: "쿠폰",
      total_balance: "총 잔액",
      impressions: "노출",
      viewable_impressions: "조회 가능한 노출",
      clicks: "클릭",
      CTR: "CTR",
      spend: "광고 비용",
      sale_amount: "판매액",
      direct_sale_amount: "직접 판매 금액",
      indirect_sale_amount: "간접 판매 금액",
      sale: "판매",
      direct_sale: "직접 판매",
      indirect_sale: "간접 판매",
      ACoS: "ACoS",
      CVR: "CVR",
      campaign_name: "이름",
      advertiser_name: "광고주",
      ad_type: "광고 유형",
      start_date: "시작 날짜",
      end_date: "종료 날짜",
      budget_type: "예산",
      bid_type: "입찰 유형",
      fixed_bid: "입찰",
      custom_key: "ID",
      ad_format: "광고 형식",
      max_ads: "최대 광고 수",
      recommended_size: "권장 크기",
      sizes: "크기",
      image_url: "크리에이티브",
      policy_status: "상태",
      transaction_date: "날짜",
      invoice_number: "송장 번호",
      transaction_type: "거래 유형",
      description: "설명",
      created_by: "작성자",
      amount: "금액",
      spend_details: "세부 사항",
      note: "노트",
      expire_date: "만료일",
      account_user_status: "활성",
      user_email: "이메일",
      account_user_role: "인증",
      user_last_login: "마지막 로그인 날짜",
      account_user_created_at: "작성일",
      email: "이메일",
      account_role: "인증",
      created_at: "초대 날짜",
      report_name: "이름",
      report_start_date: "시작 날짜",
      report_end_date: "종료 날짜",
      report_type: "리포트 유형",
      report_delivery_frequency: "빈도",
      report_created_at: "생성 날짜",
      text: "이름",
      minimum_bid: "최소 CPC",
      minimum_cpm: "최소 CPM",
      relevance: "관련성",
      restriction_type: "제한 유형",
      row_count: "행 수",
      message: "작업 메시지",
      sync_type: "동기화 유형",
      file_name: "파일 이름",
      product: "제품",
      sku: "SKU",
      buybox: "Buybox",
      city: "도시",
      age_range: "연령",
      gender: "성별",
      platform: "플랫폼",
      customer: "고객",
      category: "카테고리",
      keyword: "키워드",
      match_type: "매치 유형",
      bid: "입찰",
      category_volume: "노출량",
      active: "활성",
      paused: "일시 중지됨",
      invited: "초대됨",
      uninvited: "초대되지 않음",
      archived: "아카이브됨",
      on: "활성",
      stopped: "일시 중지됨",
      open: "열기",
      closed: "닫힘",
      coupon: "쿠폰",
      sync_log_created_at: "날짜",
      category_text: "카테고리",
      keyword_text: "키워드",
      creative_name: "이름",
      invoice: "송장 번호",
      acos: "ACoS",
      sum_click: "클릭수",
      sum_direct_sale: "직접 판매",
      sum_direct_sale_amount: "직접 판매 금액",
      sum_impression: "노출수",
      sum_sale: "판매",
      sum_sale_amount: "판매 금액",
      sum_spend: "지출",
      languages: "언어",
      video_start: "동영상 시작 수",
      video_complete: "동영상 완료 수",
      VCR: "동영상 완료율",
    },
    equality: {
      contains: "포함",
      not_contains: "포함하지 않음",
      equal: "완전 일치",
      not_equal: "일치하지 않음",
      less_than: "미만",
      grater_than: "초과",
      greater_or: "이상",
      less_or: "이하",
      any_of: "하나 이상",
      none_of: "어느 것도 아님",
    },
    filters: {
      campaignName: "이름",
      campaignStartDate: "시작 날짜",
      campaignEndDate: "종료 날짜",
      campaignStatus: "상태",
      campaignBudget: "예산",
      campaignBudgetType: "예산 유형",
      campaignBidType: "입찰 유형",
      campaignMaxAcos: "최대 ACoS",
      campaignFixedBid: "고정 입찰",
      sumSale: "판매",
      sumSpend: "소비",
      ACoS: "ACoS",
      product: "제품",
      productCampaignStatus: "상태",
      sumClick: "클릭",
      CVR: "전환율",
      keywordCampaignStatus: "상태",
      keywordText: "키워드",
      keywordMatchType: "매치 유형",
      negativeKeywordCampaignStatus: "상태",
      negativeKeywordText: "제외 키워드",
      negativeKeywordMatchType: "매치 유형",
      cityName: "도시",
      cityCampaignStatus: "상태",
      ageRangeText: "연령",
      ageRangeCampaignStatus: "상태",
      genderText: "성별",
      genderCampaignStatus: "상태",
      platformText: "플랫폼",
      platformCampaignStatus: "상태",
      customerTargetingText: "고객",
      customerTargetingCampaignStatus: "상태",
      placementText: "광고 배치",
      placementCampaignStatus: "상태",
      reportName: "리포트 이름",
      reportStartDate: "시작 날짜",
      reportEndDate: "종료 날짜",
      reportType: "리포트 유형",
      reportDeliveryFrequency: "빈도",
      reportCreatedAt: "생성 날짜",
      agencyName: "에이전시 이름",
      advertiserName: "광고주",
      advertiserStatus: "광고주 상태",
      sumSaleAmount: "판매 금액",
      sumDirectSale: "직접 판매",
      sumIndirectSale: "간접 판매",
      sumDirectSaleAmount: "직접 판매 금액",
      sumIndirectSaleAmount: "간접 판매 금액",
      sumImpression: "노출수",
      productBrand: "제품 브랜드",
      CTR: "CTR",
      keyword: "키워드",
      category: "카테고리",
      advertiserId: "광고주 ID",
      agencyId: "에이전시 ID",
      name: "제품 이름",
      brand: "브랜드",
      retailer_taxonomy: "제품 카테고리",
      advertiser_id: "광고주 ID",
    },
    equalityValues: {
      ct: "포함",
      ni: "포함하지 않음",
      eq: "완전 일치",
      ne: "일치하지 않음",
      in: "하나 이상",
      notin: "어느 것도 아님",
      lt: "미만",
      gt: "초과",
      ge: "이상",
      le: "이하",
    },
    values: {
      ACTIVE: "활성",
      PAUSED: "일시 중지됨",
      DAILY: "매일",
      TOTAL: "합계",
      STANDART: "자동",
      FIXED: "고정",
      MAX_ACOS: "최대 ACoS",
      WEEKLY: "주간",
      MONTHLY: "월간",
      EXACT: "정확",
      PHRASE: "구문",
      BROAD: "넓은 범위",
    },
    month: {
      may: "5월",
      june: "6월",
      september: "9월",
      october: "10월",
      december: "12월",
    },
  },
};

export default kr;
